<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by email or name or aff key"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-button-toolbar>
          <b-button-group>
            <b-button
              v-b-modal.promoteMultipleModal
              variant="info"
            >
              Promote users
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-modal
      id="promoteMultipleModal"
      title="Promote users by email (separated by ',')"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <text-collection-form
        items-name="Users email"
        :is-busy="promoteMultipleLoading"
        @submit="onSubmitPromoteMultiple"
      />
    </b-modal>
    <data-table
      ref="affiliateTable"
      :module-name="'partnersList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="custom.status"
      :sort-desc="false"
      :actions="actions"
      @delete="onDelete"
      @detail="onDetail"
    >
      <template #cell(user)="data">
        <b-link :to="{ name: 'affiliate-detail', params: { id: data.item[0].id } }">
          {{ data.item[0].user.firstName }} {{ data.item[0].user.lastName }}<br>
          <small>{{ data.item[0].user.email }}</small>
        </b-link>
      </template>

      <template #cell(aff_key)="data">
        {{ data.item[0].aff_key }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item[0].createdAt) }}
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status === 'asking for payout'"
          variant="light-warning"
        >
          Asking for payout
        </b-badge>
        <b-badge
          v-if="data.item[0].sign_up_at === null"
          variant="light-danger"
        >
          Unaccepted affiliate agreement yet
        </b-badge>

        <tags-list
          class="mt-1"
          :tags="data.item[0].user.tags"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import {
  BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend, BButton, BFormInput, BBadge, BLink, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import { isEmpty } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import TagsList from '@/components/page/TagsList.vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatDateTime } from '@/plugins/formaters'
import store from '@/store'
import TextCollectionForm from '@/components/forms/TextCollectionForm.vue'
import UserAPI from '@/api/user'

const actions = [
  {
    text: 'Affiliate details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Delete ',
    icon: 'Trash2Icon',
    emit: 'delete',
  },
]

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BLink,
    BBadge,
    BButtonGroup,
    BButtonToolbar,
    DataTable,
    TextCollectionForm,
    TagsList,
  },
  data() {
    return {
      promoteMultipleLoading: false,
      advancedFilter: { search: '' },
      search: '',
      fields: [
        {
          key: 'user',
          sortable: false,
        },
        {
          key: 'aff_key',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: true,
        },
        {
          key: 'status',
          sortable: true,
          sortKey: 'custom.status',
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      actions,
    }
  },
  computed: {
    ...get('partnersList', ['filters']),
  },
  beforeMount() {
    if (!isEmpty(this.filters)) {
      this.advancedFilter = { ...this.filters }
    }
  },
  methods: {
    formatDateTime,
    onDetail(item) {
      this.$router.push({ name: 'affiliate-detail', params: { id: item[0].id } })
    },
    onDelete(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Partner will be deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete partner!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('partnersList/delete', item[0].id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Delete partner',
                  icon: 'SendIcon',
                  text: 'Partner was delete successfully',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Delete partner',
                  icon: 'SendIcon',
                  text: 'Partner removing failed',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onSubmitPromoteMultiple(items) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Users will be promoted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, promote!',
      }).then(result => {
        if (result.isConfirmed) {
          this.promoteMultipleLoading = true
          UserAPI.promoteUsersToPartner(items)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'All users was promoted.',
                  icon: 'SendIcon',
                  text: 'All users was promoted.',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('promoteMultipleModal')
            })
            .catch(error => {
              if (error.status === 400) {
                Swal.fire({
                  title: 'This users can not be promoted: ',
                  text: error.data.detail,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .finally(() => {
              this.$refs.affiliateTable.reloadAll()
              this.promoteMultipleLoading = false
            })
        }
      })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
